import React from 'react'
import Hero from '../components/hero';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Hero title="Not Found" colors={['#0ff', '#00f']} />
    <div className="mt-6 container">
      <p>You just hit a route that doesn&#39;t exist.</p>
    </div>
  </Layout>
)

export default NotFoundPage
